.projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}
.card{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 18rem;
    padding: 0 20px;
    border: 5px solid #5bdb94;
    border-radius: 10px;
    background-color: #212121;
    color: #5bdb94;
    float: left;
    margin-right: 30px;
    font-family: myFirstFont;
}
.card:hover{
    background-color: antiquewhite;
    color:black
}
@media(max-width:750px){
    .share{align-items: end;
        width: 100%;
        margin-top: -98px;
    }
}

.tool p{
    margin-right: 1rem;
}
.share{
    margin-top: 32px;
}
.showMore{
    margin-top: 20px;
    background-color: #5bdb94;
    color: #212121;
    border: 2px solid #5bdb94;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
    width: 20rem;
    text-align: center;
}
.showMore:hover{
    background-color: #212121;
    color: #5bdb94;
    animation: color-change 3s infinite;
}
@keyframes color-change {
    0% { color:antiquewhite; }
    50% { color: aquamarine; }
    100% { color: #212121 ; }
  }
.joj span{
    color: #5bdb94;

}
.sow{
    text-decoration: none;
}
.heading{
    font-family: dotedf;
    color: antiquewhite;
}
@font-face {
    font-family: myFirstFont;
    src: url(../../fonts/CutiveMono-Regular.ttf);
  }
  @font-face {
    font-family: dotedF;
    src: url(../../fonts/Datdot-K7prl.ttf);
  }