
  .demo .menu-icon {
    transform: scale(1.5);
  }
  
  :root {
    --bar-bg: antiquewhite;
  }
  
  .menu-icon {
    position: relative;
    width: 20px;
    height: 25px;
    cursor: pointer;
  }
  .menu-icon .menu-icon__cheeckbox {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    z-index: 2;
    -webkit-touch-callout: none;
    position: absolute;
    opacity: 0;
  }
  .menu-icon div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 18px;
    height: 12px;
  }
  .menu-icon span {
    position: absolute;
    display: block;
    width: 100%;
    height: 1.3px;
    background-color: var(--bar-bg, #000);
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
  }
  .menu-icon span:first-of-type {
    top: 0;
  }
  .menu-icon span:last-of-type {
    bottom: 0;
  }
  .menu-icon.active span:first-of-type,
  .menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
    transform: rotate(45deg);
    top: 5px;
  }
  .menu-icon.active span:last-of-type,
  .menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
    transform: rotate(-45deg);
    bottom: 5px;
  }
  /* .menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
    width: 22px;
  } */
  @media (min-width: 1024px) {
    .menu-icon:hover span:first-of-type {
      width: 26px;
    }
    .menu-icon:hover span:last-of-type {
      width: 12px;
    }
  }
 
.navbar{
    background-color: #212121;
}
.navlinks{
  transition: all 2s ease-in-out;
}
@media (min-width: 1024px) {
  .navlinks:hover{
    background-color: #cbd2c2;
    padding: 9px 40px;
    border-radius: 50px;
    color: #000;
    
}
}

.navlinks:active{
    background-color: #8ce3ae;
    color: #05396b;
    padding: 9px 40px;
    border-radius: 50px;
    
}
.navlinks{
    margin-right: 20px;
    transition: 0.6s;
}
.navcircle:active{
    background-color: #cbd2c2;
    padding: 10px 14px;
    margin-left: 1px;
    border-radius: 100px;
    
}
/* @media(min-width:900px){
  .link1{
    position: relative;
    right: 10px;
  }
} */
@font-face {
  font-family: dotedF;
  src: url(../../fonts/Datdot-K7prl.ttf);
}
.navbarfont{
  font-family: dotedf;
}
@font-face {
  font-family: dotedF;
  src: url(../../fonts/Datdot-K7prl.ttf);
}