body{
    background-color: #212121;
    margin: 0;
    /* font-family: myFirstFont; */
}
body::-webkit-scrollbar {
    display: none;
}
html {
    scroll-behavior: smooth;
  }
  /* @font-face {
    font-family: myFirstFont;
    src: url(./fonts/DatdotBold-BWOJ8.ttf);
  } */