.next{
    padding-top: 30px;
}
.btn-me{
    color:antiquewhite;
    background-color: #5bdb94;
    padding: 10px 30px;
    text-align: center;
}
.txt{
    max-width: 200rem;
}
h1{
    
        font-family: myFirstFont;
    
}
p{
    font-family: myFirstFont;
}
a{
    font-family: myFirstFont;

}
@font-face {
    font-family: myFirstFont;
    src: url(../../fonts/CutiveMono-Regular.ttf);
  }